import { useStaticQuery, graphql } from 'gatsby';

const useTools = () => {
  const data = useStaticQuery(
    graphql`
      query ToolsQuery {
        toolsJson {
          tools {
            title
            description
            image
            link
            tags
            slug
          }
        }
      }
    `
  );
  return data.toolsJson.tools;
};

export default useTools;
