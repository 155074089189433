import React, { useState } from 'react';
import useTools from '@hooks/use-tools';
import PageLayout from '@containers/PageLayout';
import kebabCase from 'lodash/kebabCase';
import Toast from '@src/components/toast';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ToolsPage = ({ location }) => {
  const tools = useTools();
  const url = location?.href;
  const [selectedTool, setSelectedTool] = useState({});
  const [copied, setIsCopied] = useState(false);

  const copySelectedTool = (index) => {
    const selected = tools[index];
    navigator.clipboard.writeText(`${url}/${kebabCase(selected.title)}/`);
    setSelectedTool(selected);
    setIsCopied(true);
  };

  const reset = () => {
    setSelectedTool({});
    setIsCopied(false);
  };

  return (
    <PageLayout title="Tools">
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                Tools
              </h2>
              <p className="text-xl text-gray-500">
                Building products and business have something in common — it can
                get complicated. This page is a curation of the tools that I use
                in my day-to-day to approach complex problems, articulate
                solutions to produce great quality work.
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                {tools.map((tool, index) => {
                  const { title, link, description, image, tags, slug } = tool;

                  return (
                    <li key={`${index}-${title}`} style={{ listStyle: 'none' }}>
                      <div className="space-y-4">
                        <div className="aspect-w-3 aspect-h-2">
                          <img
                            className="object-cover shadow-lg rounded-lg"
                            src={image}
                            alt={title}
                          />
                        </div>
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <Link to={`${slug}/`}>
                            <h3>{title}</h3>
                          </Link>
                          <p className="text-indigo-600">
                            {tags.map((tag) => (
                              <span className="mr-2" key={tag}>
                                {tag} /
                              </span>
                            ))}
                          </p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500">{description}</p>
                        </div>
                        <ul className="flex space-x-5">
                          <li style={{ listStyle: 'none' }}>
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => copySelectedTool(index)}
                            >
                              <span className="sr-only">Copy Link</span>
                              <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                />
                              </svg>
                            </button>
                          </li>
                          <li style={{ listStyle: 'none' }}>
                            <a
                              href="#"
                              className="text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Twitter</span>
                              <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                              </svg>
                            </a>
                          </li>
                        </ul>
                        {copied && tools[index] === selectedTool ? (
                          <Toast reset={reset} text="Copied" />
                        ) : (
                          ''
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

ToolsPage.propTypes = {
  location: PropTypes.object,
};
export default ToolsPage;
